:root {
  --gold: #fcdb33;
  --green: #34c774;
  --red: #c23434;
  --grey: #d3d3d3;
}

/* @font-face {
  font-family: "Friz_Quadrata_Bold";
  src: url("./fonts/Friz Quadrata Bold.otf") format("opentype");
  font-weight: 1000px;
} */

@font-face {
  font-family: "Friz_Quadrata_Bold";
  src: url(./fonts/friz-quadrata-std-bold-587034a220f9f-webfont.woff) format("woff");
}

@font-face {
  font-family: "Friz_Quadrata_Regular";
  src: url(./fonts/friz-quadrata-std-medium-5870338ec7ef8-webfont.woff) format("woff");
}

/* @font-face {
  font-family: "OPTI-bold";
  src: url(./fonts/OPTIOptionSemiBold.otf) format("opentype");
  font-weight: 1000px;
}

@font-face {
  font-family: "OPTI-bold";
  src: url(./fonts/OPTIOptionSemiBold.otf) format("opentype");
  font-weight: 1000px;
} */

@font-face {
  font-family: "Inconsolata";
  src: local("Inconsolata"), url(./fonts/Inconsolata-Medium.ttf) format("truetype");
}

.App {
  text-align: center;
}

html {
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fafafa;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .ui.vertically.divided.grid {
  width: 200px;
} */

.three.column.row,
.one.column.row {
  /* padding-top: 0rem;
  padding-bottom: 0rem; */
}

.column {
  margin-top: 0.1rem !important;
  margin-bottom: 0.1rem !important;
}

.ui.vertically.divided.grid {
  /* margin-bottom: 2rem; */
}

.column {
  text-align: center;
}

.ui.three.column.grid,
.ui.one.column.grid {
  margin-bottom: 3rem !important;
}

* {
  /* font-family: "Roboto" !important; */
  color: #fafafa;
}

h1,
h2,
h3 {
  font-family: "Friz_Quadrata_Bold" !important;
}

.key,
.value {
  font-family: "Friz_Quadrata_Bold" !important;
}

body,
.ui.segment,
.ui.vertically.divided.grid {
  /* background-color: #323437; */
  background-color: black !important;

  font-size: 15px;
}

.ui.vertically.divided.grid.team-header.team-2 {
  background-color: #a93232;
  /* outline: 0.5px solid #a93232; */
}

.ui.vertically.divided.grid.team-header.team-1 {
  background-color: #266793;
  /* outline: 0.5px solid #266793; */
}

.playerCard,
.team-header {
  outline: 0.01rem solid var(--grey);
  margin-left: 1px !important;
  margin-right: 1px !important;
  margin-bottom: 1px !important;
  padding-top: 1px !important;
  padding-bottom: 0rem !important;
  border-right: 0;
  /* 
  outline: 1px solid;
  margin-top: 1px;
  margin-left: 1px; */
}

/* .ui.vertically.divided.grid {
 */
/* } */

a {
  color: #fafafa;
}

line.y.axis,
g.y.axis.middle {
  stroke: var(--grey);
  stroke-width: 0.8;
}

/* line.y.axis.teamTwo {
  stroke: #266793;
  stroke-width: 4;
} */

/* circle.dot.teamTwo { */
/* fill: #a93232; */
/* stroke: #323437; */
/* stroke-opacity: 1; */
/* } */

circle.dot.teamOne {
  /* fill: #266793; */
  /* stroke: #323437; */
  /* stroke-opacity: 1; */
}

.ui.vertically.divided.grid.team-header {
  margin-top: 0rem;
}

.ui.container {
  width: 400px !important;
}

.ui.grid > .row > .column {
  padding-left: 0px;
  padding-right: 0px;
}

html {
  /* line-height: 0.5; */
}

.ui.grid > .row {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

path.domain {
  stroke: white;
  stroke-width: 1px;
}

/* .ui.vertically.divided.grid.playerCard {

} */

.teamDiv {
  padding: 0.1rem;
}

.ui.vertically.divided.grid.playerCard {
  margin-top: 0rem;
}

.ui[class*="vertically divided"].grid > .row:before {
  box-shadow: 0 -0.4px 0 0 #fafafa;
}

h1.ui.header {
  color: #fafafa;
}

text.axistitle {
  fill: #fafafa;
  font-size: 10px;
  text-anchor: middle;
  /* opacity: 1; */
  /* font-weight: bold; */
}

text.mmrBackground {
  fill: #fafafa;
  font-size: 18px;
  text-anchor: middle;
}

svg#svg_logo {
  width: 50px;
  fill: #a93232;
}

.navbar {
  margin-left: -10rem;
  margin-right: -10rem;
  position: sticky;
  top: 0;
  background-color: black;
  z-index: 10000;
}

/* .navbarPlayerCard {
  margin-left: -1 rem;
  margin-right: -1 rem;
  position: sticky;
  top: 175 px !important;
  background-color: #323437;
  z-index: 10000;
} */

.matches {
  /* margin-top: 250px; */
}

.three.column.row {
  display: inline-table;
}

.crown {
  height: 12px;
  /* color: #ffd428; */
  background-color: none important!;
}

.race {
  /* width: 15px;
  height: 15px; */
}

.map,
.league {
  width: 50px;
  height: 50px;
  vertical-align: middle;
}

.mapLabel {
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 0px;
}

.timeLabel {
  /* text-transform: uppercase; */
  font-weight: 100;
  font-style: italic;
  font-size: 15px;
  margin-bottom: 10px;
}

img.profilePic {
  /* height: 100px; */
}

.gameStatus {
  padding-bottom: 10px;
}

.ui.vertically.divided.grid {
  margin-top: 1rem;
}

.navbar {
  padding-bottom: 1rem;
}

.ui.inverted.segment,
.ui.active.transition.visible.dimmer .ui.primary.inverted.segment {
  background: unset !important;
  /* color: rgba(255,255,255,.9); */
}

.ui.active.transition.visible.dimmer {
  background: unset;
}

img.logo {
  height: 75px;
}

/* i.flag {
  height: 2px !important;
  width: 2px !important;
} */

i.flag:not(.icon) {
  /* display: inline-block;
  width: 16px;
  height: 11px;
  line-height: 11px;
  vertical-align: baseline; */
  /* margin: 0.3em 2em 0 0.9em !important; */
  /* text-decoration: inherit;
  speak: none;
  font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; */
}

i.flag:not(.icon):before {
  /* width: 10px;
  height: 8px; */
}

.number,
g.tick.text {
  font-family: Inconsolata !important;
}
g.tick.text {
  opacity: 0.5;
}

h5.number {
  font-family: Inconsolata !important;
  font-weight: 500;
  /* font-size: 0.9rem; */
}

circle.dot {
  stroke: var(--gold);
  fill: var(--gold);
  stroke-width: 0.5px;
  stroke-opacity: 0.8;
}

.profileName {
  font-size: 20px;
}

img.profilePic {
  width: 75px;
}

h5.profileName {
  text-align-last: center;
}

.league {
  text-transform: uppercase;
  /* font-weight: bold; */
  margin-top: 5px;
  margin-bottom: 0px;
}

/* .leagueContainer {
  position: relative;
  text-align: center;
}

.leagueRank {
  position: absolute;
  top: 40px;
  left: 50px;
  color: #fff !important;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000 !important;
} */

.row.middleprofilediv {
  margin-top: 10px;
}

span.false {
  background: red;
  color: white;
  padding: 2px;
  font-weight: 800;
}

span.true {
  background: green;
  color: white;
  padding: 2px;
  font-weight: 800;
}

.playerMMrstat {
  /* margin-top: 25px; */
  /* font-size: 21px; */
  /* text-align: center; */

  -webkit-animation: fadeinout ease 10s infinite; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinout ease 10s infinite; /* Firefox < 16 */
  -ms-animation: fadeinout ease 10s infinite; /* Internet Explorer */
  -o-animation: fadeinout ease 10s infinite; /* Opera < 12.1 */
  animation: fadeinout ease 10s infinite;
  /* color: red;
  background: red; */
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.match {
  -webkit-animation: fadein ease 10s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein ease 10s; /* Firefox < 16 */
  -ms-animation: fadein ease 10s; /* Internet Explorer */
  -o-animation: fadein ease 10s; /* Opera < 12.1 */
  animation: fadein ease 10s;
}

.table {
  background-image: url(https://github.com/w3champions/website/blob/d9eb0ddbab4f8493346434cdede16294ea3fccb6/public/assets/backgrounds/undead.jpg?raw=true) !important;
}

.compactTable {
  background-image: unset !important;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.name {
  -webkit-animation: fadeinname ease 10s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinname ease 10s; /* Firefox < 16 */
  -ms-animation: fadeinname ease 10s; /* Internet Explorer */
  -o-animation: fadeinname ease 10s; /* Opera < 12.1 */
  animation: fadeinname ease 10s;
}

@keyframes fadeinname {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.numberCircle {
  font-weight: bolder;
}

.sixteen.wide.column.playerName {
  padding-top: 3px !important;
}

.three.column.row.playerBottom {
  padding-top: 5px !important;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ .coloring.black:before,
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ .coloring.black:before {
  background: red !important;
}

.ui.toggle.checkbox input:focus:checked ~ .coloring.white:before,
.ui.toggle.checkbox input:checked ~ .coloring.white:before {
  background: yellow !important;
}

.pulsating-circle {
  position: absolute;
  /* left: 50%; */
  /* top: 50%; */
  /* transform: translateX(-50%) translateY(-50%); */
  width: 15px;
  height: 15px;
  float: right;
}

.pulsating-circle:after {
  content: "";
  position: absolute;
  display: inline-block;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: red;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

#wrapper {
  border: 1px solid blue;
}

#div1 {
  display: inline-block;
  width: 120px;
  height: 120px;
  border: 1px solid red;
}
#div2 {
  display: inline-block;
  width: 160px;
  height: 160px;
  border: 1px solid green;
}

.timeDiv {
  /* float: right; */
  /* display: inline-block; */
}

.pulsating-circle {
  padding-top: 10px !important;
  margin-top: 2px;
  margin-left: 30px;
}

p.mapLabel {
  margin-left: -20px;
  margin-right: -20px;
}

.tableContainer {
  display: flex;
  justify-content: center;
}

.ui.vertically.divided.grid.team-header {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.ui.vertically.divided.grid.playerCard:nth-of-type(5) {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

/* .playerName {
  font-family: "Roboto Bold";
  font-size: 1.15rem !important;
  font-weight: 800;
} */

.number {
  /* font-size: 1.15rem !important; */
}

td {
  /* min-width: 160px; */
}

.map-icon {
  width: 150px;
  height: 150px;
}

.profile-pic {
  width: 60px;
}

.matchDetails {
  width: max-content;
}

.red-text {
  color: var(--red);
}

td.yellow-text.number.team-0 {
  color: #fffdd0;
}

.green-text {
  color: var(--green);
}

h2 {
  color: var(--gold) !important;
  /* font-family: "OPTI-bold"; */
  font-size: 1.5rem !important;
}

.th-center {
  text-align: center !important;
}
.th {
  width: 100px;
}

.td {
}

.number {
  font-family: "Inconsolata" !important;

  font-size: large !important;
  font-weight: bolder !important;
}

.team-0 {
  text-align: right !important;
}

.team-1 {
  text-align: left !important;
}

.key {
  color: var(--grey);
  text-transform: uppercase;
}

.value {
  color: white;
}

.hero-pic {
  margin-right: 5px;
  flex: 0 0 auto;
  width: 35px;
  height: 35px;
}

.hero-bit .team-0 {
  float: right;
}

.hero-bit team-1 {
  float: left;
}

.matchDetails {
  position: relative;
}

.matchDetails > div {
  position: relative;
  z-index: 1; /* or any value less than the z-index of .matchDetails */
}

.matchDetails::before {
  content: "";
  position: absolute;
  opacity: 0.3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(https://github.com/w3champions/website/blob/d9eb0ddbab4f8493346434cdede16294ea3fccb6/public/assets/backgrounds/undead.jpg?raw=true) !important;
  background-size: cover;
  z-index: -1;
}

.matchDetails > div {
  background-color: transparent;
}

.matchDetails .ui.segment table td {
  background-color: transparent !important;
}

.ui.segment {
  opacity: 0.75;
}

.ui.segment .profile-pic,
.ui.segment .text {
  opacity: 1;
}

/*  */

p.number.heroLevel {
  line-height: 1rem;
}

.flag {
  top: 5px !important;
}

.flag.team-0 {
  right: 5px !important;
  margin-right: 0px !important;
}

.flag.team-1 {
  left: 5px !important;
  margin-left: 0px !important;
}

path.line {
  stroke: white;
  stroke-width: 1.5 px;
}

line.line.team-middle {
  stroke: var(--grey);
  stroke-width: 0.5px;
}

img.race {
  margin-bottom: -7px;
  width: 35px !important;
}

.Game {
  padding-bottom: 75px;
}

table.ui.small.inverted.basic.compact.table {
  border: 2px solid grey;
  table-layout: auto;
  width: 150px;
}

.Game {
  /* padding: 70px; */
}

.max-width-cell {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.live-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  display: inline-block; /* Ensures it's displayed inline */
  vertical-align: middle; /* Vertically aligns it with the text */
  margin-left: 5px; /* Adjust the margin as needed */
  animation: pulse 1s infinite alternate;
  margin-bottom: 2px !important;
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

#PlayerProfile {
  /* background-color: magenta; */
}

.ui.table {
}

.playerDiv {
  width: 200px;
  /* overflow: hidden; */
}

.compact {
  width: 100px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
}

.games {
  display: grid;
  place-items: center;
}

th {
  border: none !important;
}

.image-container {
  /* overflow-x: auto; */
  /* white-space: nowrap; */
}

.image-container {
  padding-top: 8px;
  margin-bottom: -10px;
}

img.mvpIconRight {
  padding-left: 10px;
}

img.mvpIconLeft {
  padding-right: 10px;
}
